@import "utils/_normalize.scss";

:root {
    --red: #ee1b2e;
    --grey: #353535;
}

body {
    background: transparent url(../images/DSC_4081-Pano.jpg) no-repeat center center;
    background-size: cover;
    background-position: center;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: normal;
    height: 100dvh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    justify-content: center;
    .content {
        padding: 40px 20px;
        box-sizing: border-box;
        z-index: 2;
        width: 100%;
        text-align: center;
        .intro {
            width: 100%;
            max-width: 1100px;
            text-align: center;
            margin: 0 auto;
            margin-bottom: 10vh;
            h1 {
                color: var(--red);
                font-size: 36px;
            }
            p {
                font-size: 24px;
                line-height: 34px;
                color: var(--grey);
            }
        }
        .boxes {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            .row {
                width: 50%;
                padding: 40px;
                max-width: 550px;
                box-sizing: border-box;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                img {
                    width: 100%;
                    height: 110px;
                    object-fit: contain;
                    object-position: center;
                }
                a.btn {
                    background-color: var(--red);
                    color: white;
                    padding: 20px 80px;
                    text-decoration: none;
                    border-radius: 5px;
                    font-size: 20px;
                    margin: 20px 0;
                    &:hover {
                        background-color: var(--grey);
                        color: white;
                    }
                }
                a {
                    color: var(--grey);
                    font-size: 20px;
                    text-decoration: none;
                    transition: all .3s ease-in-out;
                    &:hover {
                        color: var(--red);
                    }
                }
            }
            .row:last-child {

            }
        }
    }
    &:after {
        content: "";
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 110%;
        background-color: white;
        opacity: 0.8;
        left: 0;
        top: 0;
    }
}

@media screen and (max-width: 844px) {
    html {
        height: 100dvh;
    }
    body {
        height: 100%;
        .content {
            overflow: scroll;
            height: 100dvh;
            .intro {
                padding: 80px 0 0 0;
                h1 {
                    font-size: 25px;
                }
                p {
                    font-size: 18px;
                    line-height: 28px;
                }
            }
            .boxes {
                display: block;
                .row {
                    width: 100%;
                    padding: 0;
                    margin-bottom: 40px;
                    max-width: auto;
                    a.btn {
                        padding: 20px 40px;
                    }
                }
            }
        }
    }
} 